/*
 * Drupal behavior to attach a click listener to the offcanvas data-toggle element 
 * http://stackoverflow.com/questions/19397140/collapsing-sidebar-with-bootstrap-3
 * Example: http://www.bootply.com/88026
 */
(function ($, Drupal) {
Drupal.behaviors.blockUIOnSaveOrNext = {
    attach: function() { 

      $('#edit-save').off('click');
      $('#edit-save-bottom').off('click');
      $('#edit-submit--2').off('click');

      //$('#edit-next').off('click');

      justClicked = false;

      // This is how we prevent the normal form submission when the user hits the enter key when focused in an input or select
      //$('[type="submit"]').not('#edit-preview').not('#edit-delete').on('click', function( event ) {
      $('#edit-save').on('click', function( event ) {
    
            //alert("save justClicked: "+justClicked);

            if (justClicked === false) {

                justClicked = true;

                //targetId = $(event.target).attr('id');

                //alert("targetId: "+targetId);

                $('[type="submit"]').not('#edit-save').attr('disabled', 'disabled');

            } else {

                //alert("preventing default behavior of save");
                event.preventDefault();
            }

            return true;
      });

      $('#edit-save-bottom').on('click', function( event ) {
    
            //alert("save justClicked: "+justClicked);

            if (justClicked === false) {

                justClicked = true;

                //targetId = $(event.target).attr('id');

                //alert("targetId: "+targetId);

                $('[type="submit"]').not('#edit-save-bottom').attr('disabled', 'disabled');

            } else {

                //alert("preventing default behavior of save");
                event.preventDefault();
            }

            return true;
      });

      $('#edit-submit--2').on('click', function( event ) {
    
            //alert("save justClicked: "+justClicked);

            if (justClicked === false) {

                justClicked = true;

                //targetId = $(event.target).attr('id');

                //alert("targetId: "+targetId);

                $('[type="submit"]').not('#edit-submit--2').attr('disabled', 'disabled');

            } else {

                //alert("preventing default behavior of save");
                event.preventDefault();
            }

            return true;
      });

      //alert("adding click handler for #edit-next");

      // This is how we prevent the normal form submission when the user hits the enter key when focused in an input or select
      //$('[type="submit"]').not('#edit-preview').not('#edit-delete').on('click', function( event ) {
      /*$('#edit-next').on('click', function( event ) {
    
            //alert("save/continue justClicked: "+justClicked);

            if (justClicked === false) {

                justClicked = true;

                targetId = $(event.target).attr('id');

                //alert("targetId: "+targetId);

                $('[type="submit"]').not('#'+targetId).attr('disabled', 'disabled');

            } else {

                //alert("preventing default behavior of save/continue");
                event.preventDefault();
            }
            
            return true;
      });*/
  }

}})(jQuery, Drupal);

(function ($) {
  Drupal.behaviors.npa_cje_filter = {
    attach: function(context, settings) {

      if (Drupal.settings.hasOwnProperty('cje_tids')) {
        $('.cje_subs').hide();  // hide all topics

        // HFA and validation functions pass list of tids selected
        cje_tids = Drupal.settings.cje_tids;
        var glyphs = Drupal.settings.cje_glyphs;
      
        // show all sub topic checkboxes matching default values
        $.each(cje_tids, function(index, value) {
            // find checkbox with this value
            cb = $(':checkbox[value="'+value+'"]');
            // find parent sub-term element and display it
            cb.closest('.cje_subs').show();

            // change glyph to collapse
            cb.closest('.cje_topic').find('.cje-sub-ctrl')
              .removeClass(glyphs.expand).addClass(glyphs.collapse);

          });

        // toggle sub topic boxes and clear any subs if main gets unchecked
        $('.cje_main .cje-sub-ctrl').click(function() {
            target = $(this).closest('.cje_topic').find('.cje_subs');

            if ($(this).hasClass(glyphs.expand)) {
              $(target).show();
              $(this).removeClass(glyphs.expand).addClass(glyphs.collapse);
            } else {
              $(target).hide();
              $(this).removeClass(glyphs.collapse).addClass(glyphs.expand);
            }
            // do not propagate event
            return false;
          });
      }
    }
  }
}) (jQuery);

(function ($) {
  Drupal.behaviors.npa_cje = {
    attach: function(context, settings) {

      if (Drupal.settings.hasOwnProperty('cje_tids')) {

        $('.cje_subs').hide();  // hide all topics

        // HFA and validation functions pass list of tids selected
        cje_tids = Drupal.settings.cje_tids;

        // show all sub topic checkboxes matching default values
        $.each(cje_tids, function(index, value) {
            $('.cje_subs[data-cje="'+value+'"]').show();
          });

        // toggle sub topic boxes and clear any subs if main gets unchecked
        $('.cje_main :checkbox').change(function() {
            target = $(this).closest('.cje_topic').find('.cje_subs');
	
            if(!this.checked) {
              $(target).hide();
              $(target).find(':checkbox').attr('checked', false);
            } else {
              $(target).show();
            }
          });
      }
    }
  }
}) (jQuery);

/*
 * Drupal behavior to attach a click listener to the offcanvas data-toggle element 
 * http://stackoverflow.com/questions/19397140/collapsing-sidebar-with-bootstrap-3
 * Example: http://www.bootply.com/88026
 */

(function ($, Drupal) {

Drupal.behaviors.offCanvasSidebarToggle = {
    attach: function() { 

        // All clicks inside the responsive-sidebar wrapper div will bubble up
        // to this event listener.  So, we can handle everything here.
        $('.responsive-sidebar').on('click', function( event ) {

            // If the navbar-toggle was clicked, then toggle the sidebar active.
            // It's also possible that one of the icon-bars children was clicked.
            if( ($(event.target).attr('class') === 'navbar-toggle') ||
                ($(event.target).parent().attr('class') === 'navbar-toggle')) {
                $('.responsive-sidebar').toggleClass('active');
            }

            var element_id = $(event.target).attr('id'); 

            // If one of the topic areas links was clicked and the sidebar is
            // active, then we will need to toggle it to be inactive.
            if( (element_id === 'menu_adjudication') ||
                (element_id === 'menu_corrections') ||
                (element_id === 'menu_terrorism') ||
                (element_id === 'menu_crime') ||
                (element_id === 'menu_information') ||
                (element_id === 'menu_lawenforce') ||
                (element_id === 'menu_mental') ||
                (element_id === 'menu_substance') ||
                (element_id === 'menu_tribal') ||
                (element_id === 'menu_capacity')) {

                if($('.responsive-sidebar').attr('class').indexOf('active') > 0) { 

                    $('.responsive-sidebar').toggleClass('active');

                    $('html, body').animate({
                        scrollTop: $(element_id).offset().top
                    }, 500);

                    // Prevent the default behavior which would be to 
                    // go down to the anchor tag.
                    event.preventDefault(); 
                }
            }
        });

        // All clicks inside the site-navigation wrapper div will bubble up
        // to this event listener.  So, we can handle everything here.
        $('#site-navigation').on('click', function( event ) {

            var element_id = $(event.target).attr('id'); 

            // If one of the topic areas links was clicked and the sidebar is
            // active, then we will need to toggle it to be inactive.
            if( (element_id === 'menu_adjudication') ||
                (element_id === 'menu_corrections') ||
                (element_id === 'menu_terrorism') ||
                (element_id === 'menu_crime') ||
                (element_id === 'menu_information') ||
                (element_id === 'menu_lawenforce') ||
                (element_id === 'menu_mental') ||
                (element_id === 'menu_substance') ||
                (element_id === 'menu_tribal') ||
                (element_id === 'menu_capacity')) {

                if($('.responsive-sidebar').attr('class').indexOf('active') > 0) {   

                    $('.responsive-sidebar').toggleClass('active');

                    $('html, body').animate({
                        scrollTop: $(element_id).offset().top
                    }, 500);

                    // Prevent the default behavior which would be to 
                    // go down to the anchor tag.
                    event.preventDefault(); 
                }
            }
        });
    }
};

})(jQuery, Drupal);

(function ($) {
  Drupal.behaviors.bja_pane_eq_height = {
    attach: function (context, settings) {

      var body = $('body');
      $('<div class="device-xs visible-xs"></div>').appendTo(body);
      $('<div class="device-sm visible-sm"></div>').appendTo(body);
      $('<div class="device-md visible-md"></div>').appendTo(body);
      $('<div class="device-lg visible-lg"></div>').appendTo(body);

      function isBreakpoint( alias ) {
	return $('.device-' + alias).is(':visible');
      }
      
      if( !isBreakpoint('xs') ) {

	var max = 0;
	$('.title_plus_content').each(function(index) {
	  if ( $(this).outerHeight(true) > max )
	    max = $(this).outerHeight(true);
	});

	$('.title_plus_content').each(function() {      
	  $(this).height(max);
	});
      }
    }
  }
}(jQuery));

(function ($) {
  Drupal.behaviors.printExpandFieldset = {
    attach: function (context, settings) {
      $('.fieldset.collapsed').collapse('show');
      $('.fieldset.collapsed').addClass('nja');
    }
  }
})(jQuery);

/*
 * Post process some DOM elements to bring them into 508 compliance
 */
(function ($) {
  Drupal.behaviors.section508fixes = {
    attach: function (context, settings) {

      function waitForElementToDisplay(selector, time, action) {
        if(document.querySelector(selector)!=null) {
	  action();
          return;
        }
        else if (time.attempts > 0) {
	  
          setTimeout(function() {
            waitForElementToDisplay(selector, time, action);
          }, time.interval);
	  time.attempts--;
        }
      }

      /*
       * colorbox elements are created via jquery.colorbox-min.js added
       * in nttac_process_page.  We have to wait for them to be in place
       * before we can modify the buttons to be non-empty.
       */

      var time = { interval : 100, attempts : 5 };
      
      waitForElementToDisplay('#colorbox', time, function() {
	$("#cboxPrevious").append('<span class="sr-only">colorbox previous</span>');
	$("#cboxNext").append('<span class="sr-only">colorbox next</span>');
	$("#cboxSlideshow").append('<span class="sr-only">colorbox slideshow</span>');

	// move colorbox HTML into page-bottom section
	$(".region-page-bottom").append($('#colorbox'));
      });

      /*
       * The print/mail/pdf links have icon images with same alt/title text.
       * Replace alt text with something more descriptive.
       */
      time.attempts = 3;
      waitForElementToDisplay('#block-print-ui-print-links', time, function() {
	// find link parent element to use as context in subsequent selectors
	links = $('#block-print-ui-print-links').first();
	$('.print_html > a > img', links).attr('alt', 'icon image of printer'); 
	$('.print_mail > a > img', links).attr('alt', 'icon image of envelope'); 
	$('.print_pdf > a > img', links).attr('alt', 'icon image of PDF symbol');      });

      /*
       * nttac-2534 : accordion links target non-existent anchors
       * Add an id to the parent element equal to the name referenced in the
       * link href attribute (minus the leading '#').
       */
      $('.views-field.views-accordion-header a').each( function( key, value ) {
	name = $(value).attr('href').substring(1);
	$(value).parent().attr('id', name);
      });

      
      // 3.3.1 Error Identification
      // Aria-atomic="true" missing on error container
      time.attempts = 2;
      waitForElementToDisplay('[aria-live="assertive"]', time, function() {
	$('[aria-live="assertive"]').attr('aria-atomic', "true");
      });

      /*
       * nttac-2600 508 Compliance - "Aria-labelledby" has incorrect (ID) reference
       * Views accordion header used by FAQ pages suffers here.
       *
       * Siteimprove complains that the aria-labelledby="ui-accordion-1-header-0" 
       * does not point to an existing element with that id. 
       * Remove this attribute.
       */
      $('.ui-accordion-content').removeAttr('aria-labelledby');

      /*
       * nttac-2601 508 Compliance: iFrame is missing a description.
       * Find the main title for the page and put that into the iframe title.
       * Also, remove the frameborder attribute (should be in CSS, not inline). 
       */
      time.attempts = 2;
      waitForElementToDisplay('iframe', time, function() {
	iframe = $('iframe');
	var title = iframe.closest('div.main-container').find('h1.page-header').html();
	iframe.attr('title', title);
	iframe.removeAttr('frameborder');
      });

    }
  }
})(jQuery);

(function ($) {
  Drupal.behaviors.bja_bs_carousel = {
    attach: function (context, settings) {

      $('.view-content .carousel').carousel({
	pause: 'false'
      });
      $('.bsPlayButton').click(function () {
	$(this).closest('.view-content').find('.carousel').carousel('next').carousel('cycle');
      });
      $('.bsPauseButton').click(function () {
	$(this).closest('.view-content').find('.carousel').carousel('pause');
      });
      
    }
  }
}) (jQuery);

